import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './SectionIntro.module.scss';
import Modal from '../../global/Modal/Modal';

const SectionIntro = () => {
    const items = [
        'Pengen jadi hacker, tapi nggak tau gimana cara mulai belajarnya?',
        'Udah bikin sistem, tapi sering banget kena hack? Pengen tau cara mengamankannya?',
        'Mau berkarir sebagai ahli keamanan cyber, tapi nggak ngerti roadmapnya?',
        'Pengen punya penghasilan tambahan sebagai bug hunter atau konsultan keamanan cyber, tapi nggak tau caranya?',
        'Atau kamu cuma pengen tau gimana sih caranya seorang hacker bisa meretas sebuah sistem?'
    ];

    return (
        <>
            <section>
                <div className="container text-white py-5 px-4 px-lg-5">
                    <div className="row my-5">
                        <div className="col-lg-6 my-auto mb-4 mb-lg-auto">
                            <img className="img-fluid" src="/assets/img/img-intro.png" loading="lazy" alt="Intro" />
                        </div>
                        <div className="col-lg-6 my-auto ps-lg-5">
                            {items?.map((item, index) => {
                                return (
                                    <div className="d-flex gap-2" key={index}>
                                        <FontAwesomeIcon className="text-info mt-1" icon={faQuestionCircle} />
                                        <p>{item}</p>
                                    </div>
                                );
                            })}

                            <p className="fw-bolder">Kalau salah satu dari pertanyaan di atas adalah kamu banget, mungkin program ini cocok buat kamu.</p>
                        </div>
                    </div>

                    <div className="row my-5 py-5">
                        <div className="col-lg-6 my-auto">
                            <h2 className="section-title mb-5">Tentang <span className="text-info">Kursushacker</span></h2>
                            <p>KursusHacker adalah sebuah kelas online yang dirancang khusus untuk memandu individu dalam memahami dunia cyber security mulai dari dasar hingga mahir. Dengan roadmap yang jelas dan materi yang terstruktur, program ini membantu peserta untuk menjadi seorang hacker etis yang kompeten. Dibimbing langsung oleh praktisi cyber security berpengalaman, Putra Aji Adhari.</p>
                        </div>
                        <div className="col-lg-6 my-auto">
                            <div className={styles.img_wrapper}>
                                <img className="img-fluid" src="/assets/img/img-about.png" loading="lazy" alt="About" />
                                <div className={styles.img_overlay}>
                                    <button type="button" className={`btn bg-dark rounded-circle shadow p-1 ${styles.btn_play}`} data-bs-toggle="modal" data-bs-target="#introModal" title="Play">
                                        <FontAwesomeIcon size="4x" className="text-info" icon={faPlayCircle} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-7 my-auto order-lg-last ps-lg-5">
                            <h2 className="section-title mb-5">Siapa <span className="text-info">Putra Aji Adhari?</span></h2>
                            <p>Putra Aji Adhari bukanlah sosok yang asing dalam dunia cyber security. Pernah mendeteksi kerentanan di situs NASA pada tahun 2014, ia telah membangun reputasi sebagai salah satu Bug Hunter terkemuka yang dihargai oleh banyak organisasi global. Saat ini, ia memegang peran penting dalam tim cyber security di Bank BCA dan beberapa perusahaan multinasional lainnya. Dengan kekayaan pengalaman dan keahliannya, Putra Aji berkomitmen untuk memandu kamu dalam belajar cyber security secara efektif dan terstruktur. Bersama kami di program ini, persiapkan diri kamu untuk menjadi ahli cyber security yang sangat dicari oleh perusahaan-perusahaan top di seluruh dunia.</p>
                        </div>
                        <div className="col-lg-5 my-auto">
                            <img className="img-fluid" src="/assets/img/img-putra.png" loading="lazy" alt="Putra Aji Adhari" />
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                id="introModal"
                title="Intro"
                modalSize="modal-xl"
            >
                <div className="ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/NBokG0vgAHY?si=HOgs_KN-EAQwl5dT" title="YouTube video" loading="lazy" allowFullScreen />
                </div>
            </Modal>
        </>
    );
};

export default SectionIntro;
