import styles from './SectionComparing.module.scss';

const SectionComparing = () => {
    return (
        <section className="text-white">
            <div className="container p-5">
                <div className="text-center my-5">
                    <h2 className="section-title fw-bold mb-3">Belajar Sendiri VS <span className="text-info">Kursushacker</span></h2>
                </div>
                <div className="card card-gradient bg-transparent border-info">
                    <div className="card-body">
                        <div className="row gx-0">
                            <div className="col-xl-4 bg-dark d-none d-xl-block">
                                <div className="p-4">
                                    <div className="d-flex">
                                        <h5 className="section-title">Fasilitas Belajar</h5>
                                    </div>

                                    <hr className="border-top border-white border-3 opacity-100 w-25 me-auto mb-4" />

                                    <div style={{ height: '170px' }}>
                                        <p className="fw-bolder">Cara belajar</p>
                                    </div>
                                    <div style={{ height: '170px' }}>
                                        <p className="fw-bolder">Media belajar</p>
                                    </div>
                                    <div style={{ height: '170px' }}>
                                        <p className="fw-bolder">Jika ada kesulitan dalam belajar</p>
                                    </div>
                                    <div style={{ height: '320px' }}>
                                        <p className="fw-bolder">Mentor</p>
                                    </div>
                                    <div style={{ height: '300px' }}>
                                        <p className="fw-bolder">Harga</p>
                                    </div>
                                    <div style={{ height: '150px' }}>
                                        <p className="fw-bolder">Sertifikat</p>
                                    </div>
                                    <div style={{ height: '150px' }}>
                                        <p className="fw-bolder">Teman belajar</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 d-none d-xl-block bg-transparent">
                                <div className="p-4">
                                    <div className="d-flex justify-content-center">
                                        <h5 className="section-title">Belajar Sendirian</h5>
                                    </div>

                                    <hr className="border-top border-white border-3 opacity-100 w-25 mx-auto" />

                                    <div className="p-3" style={{ height: '160px' }}>
                                        <span className="d-xl-none">Cara belajar</span>
                                        <p>Meraba-raba sendiri, dari 1 website ke website lain, dari 1 platform ke platform lain.</p>
                                    </div>

                                    <div className="p-3" style={{ height: '170px' }}>
                                        <span className="d-xl-none">Media belajar</span>
                                        <p>Seadanya yang ditemukan di internet.</p>
                                    </div>

                                    <div className="p-3" style={{ height: '170px' }}>
                                        <span className="d-xl-none">Jika ada kesulitan dalam belajar</span>
                                        <p>Cari solusi sendiri di internet.</p>
                                    </div>

                                    <div className="p-3" style={{ height: '320px' }}>
                                        <span className="d-xl-none">Mentor</span>
                                        <p>Tidak ada mentor yang terikat. Hanya menunggu konten kreator atau penulis blog yang berbaik hati untuk sharing. Dan mereka tidak punya tanggung jawab apapun dari konten yang dibagikan.</p>
                                    </div>

                                    <div className="p-3" style={{ height: '300px' }}>
                                        <span className="d-xl-none">Harga</span>
                                        <p>Gratis. Modal kuota internet aja udah bisa.</p>
                                    </div>

                                    <div className="p-3" style={{ height: '150px' }}>
                                        <span className="d-xl-none">Sertifikat</span>
                                        <p>Ya nggak ada</p>
                                    </div>

                                    <div className="p-3" style={{ height: '150px' }}>
                                        <span className="d-xl-none">Teman belajar</span>
                                        <p>Ada, bisa cari sendiri di forum-forum belajar coding di internet.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 d-none d-xl-block bg-dark border border-3 border-info rounded-3 shadow">
                                <div className="p-4">
                                    <div className="d-flex justify-content-center">
                                        <img height="30px" src="/assets/img/logo-kursushacker.png" alt="Kelas Hacker" />
                                    </div>

                                    <hr className="border-top border-white border-3 opacity-100 w-25 mx-auto" />

                                    <div className="p-3" style={{ height: '160px' }}>
                                        <span className="d-xl-none">Cara belajar</span>
                                        <p>Mengikuti alur belajar terarah dari A-Z, dari Zero to Hero yang disusun oleh para expert.</p>
                                    </div>

                                    <div className="p-3" style={{ height: '170px' }}>
                                        <span className="d-xl-none">Media belajar</span>
                                        <p>10+ Modul pembelajaran yang bisa diakses. Materi terus bertambah dan diupdate sesuai tren teknologi.</p>
                                    </div>

                                    <div className="p-3" style={{ height: '170px' }}>
                                        <span className="d-xl-none">Jika ada kesulitan dalam belajar</span>
                                        <p>Tinggal tanya di group private Telegram atau ditanyakan saat konsultasi online bulanan via Zoom.</p>
                                    </div>

                                    <div className="p-3" style={{ height: '320px' }}>
                                        <span className="d-xl-none">Mentor</span>
                                        <p>Ada mentor-mentor yang bertanggung jawab terhadap materi belajar. Para mentor akan membantu jika peserta mengalami kesulitan dalam belajar melalui forum tanya jawab via Telegram dan konsultasi online bulanan.</p>
                                    </div>

                                    <div className="p-3" style={{ height: '300px' }}>
                                        <span className="d-xl-none">Harga</span>
                                        <p>Ada harga yang harus dibayarkan. Jalan kaki dari Jakarta ke Jogja juga gratis kok, tapi capek. Kalau mau nggak capek, bahkan bisa sambil tidur, bisa naik kereta api atau pesawat, tapi harus bayar.</p>
                                    </div>

                                    <div className="p-3" style={{ height: '150px' }}>
                                        <span className="d-xl-none">Sertifikat</span>
                                        <p>Ada sertifikat digital setiap kali menyelesaikan 1 modul belajar.</p>
                                    </div>

                                    <div className="p-3" style={{ height: '150px' }}>
                                        <span className="d-xl-none">Teman belajar</span>
                                        <p>Ada forum silaturahmi member. Bisa kenalan sesama member. Senasib seperjuangan.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 d-xl-none bg-dark">
                                <div className="p-4">
                                    <div className="d-flex justify-content-center">
                                        <h5 className="section-title">Belajar Sendirian</h5>
                                    </div>

                                    <hr className="border-top border-white border-3 opacity-100 w-25 mx-auto" />

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Cara belajar</span>
                                        <p>Meraba-raba sendiri, dari 1 website ke website lain, dari 1 platform ke platform lain.</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Media belajar</span>
                                        <p>Seadanya yang ditemukan di internet.</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Jika ada kesulitan dalam belajar</span>
                                        <p>Cari solusi sendiri di internet.</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Mentor</span>
                                        <p>Tidak ada mentor yang terikat. Hanya menunggu konten kreator atau penulis blog yang berbaik hati untuk sharing. Dan mereka tidak punya tanggung jawab apapun dari konten yang dibagikan.</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Harga</span>
                                        <p>Gratis. Modal kuota internet aja udah bisa.</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Sertifikat</span>
                                        <p>Ya nggak ada</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Teman belajar</span>
                                        <p>Ada, bisa cari sendiri di forum-forum belajar coding di internet.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 d-xl-none border border-3 border-info rounded-3 mb-3 shadow">
                                <div className="p-4">
                                    <div className="d-flex justify-content-center">
                                        <img height="30px" src="/assets/img/logo-kursushacker.png" alt="Kursus Hacker" />
                                    </div>

                                    <hr className="border-top border-white border-3 opacity-100 w-25 mx-auto" />

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Cara belajar</span>
                                        <p>Mengikuti alur belajar terarah dari A-Z, dari Zero to Hero yang disusun oleh para expert.</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Media belajar</span>
                                        <p>10+ Modul pembelajaran yang bisa diakses. Materi terus bertambah dan diupdate sesuai tren teknologi.</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Jika ada kesulitan dalam belajar</span>
                                        <p>Tinggal tanya di group private Telegram atau ditanyakan saat konsultasi online bulanan via Zoom.</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Mentor</span>
                                        <p>Ada mentor-mentor yang bertanggung jawab terhadap materi belajar. Para mentor akan membantu jika peserta mengalami kesulitan dalam belajar melalui forum tanya jawab via Telegram dan konsultasi online bulanan.</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Harga</span>
                                        <p>Ada harga yang harus dibayarkan. Jalan kaki dari Jakarta ke Jogja juga gratis kok, tapi capek. Kalau mau nggak capek, bahkan bisa sambil tidur, bisa naik kereta api atau pesawat, tapi harus bayar.</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Sertifikat</span>
                                        <p>Ada sertifikat digital setiap kali menyelesaikan 1 modul belajar.</p>
                                    </div>

                                    <div className={`${styles.row} p-3`}>
                                        <span className="d-xl-none">Teman belajar</span>
                                        <p>Ada forum silaturahmi member. Bisa kenalan sesama member. Senasib seperjuangan.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default SectionComparing;
