import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionFaq = () => {
    const faqs = [
        {
            id: 1,
            question: 'Apa yang akan saya pelajari dalam program kelas hacker ini?',
            answer: 'Dalam program ini, Anda akan mempelajari teknik-teknik dasar dan lanjutan dalam keamanan komputer, seperti penetrasi jaringan, analisis kerentanan, kriptografi, dan pemahaman tentang serangan cyber umum lainnya.'
        },
        {
            id: 2,
            question: 'Siapakah pakar-pakar yang akan mengajar dalam program ini?',
            answer: 'Program ini akan dipandu oleh para pakar yang memiliki pengalaman luas dalam bidang keamanan komputer dan hacking etis. Mereka memiliki sertifikasi dan rekam jejak yang solid dalam industri keamanan'
        },
        {
            id: 3,
            question: 'Apakah program ini legal dan etis?',
            answer: 'Ya, program ini sepenuhnya legal dan menekankan etika dalam pengajaran. Kami menekankan pemahaman tentang pentingnya menggunakan pengetahuan ini secara bertanggung jawab dan sesuai hukum.'
        },
        {
            id: 4,
            question: 'Apakah ada prasyarat tertentu untuk bergabung dalam program ini?',
            answer: 'Semua orang bisa mengikuti kelas ini asalkan memiliki niat kuat untuk bisa belajar tentang hacker. Selain itu, kemauan untuk belajar dan keterbukaan untuk mengikuti pedoman etika hacking sangat diperlukan.'
        },
        {
            id: 5,
            question: 'Bagaimana metode pembelajaran yang digunakan dalam program ini?',
            answer: 'Materi di sediakan dengan e-learning jadi kapan pun dan dimanapun kalian belajar flexibel. Kalian bisa belajar dimana saja dan kapan saja, dan lebih seru lagi ada live session di setiap bulannya.'
        },
        {
            id: 6,
            question: 'Apakah sertifikat atau pengakuan diberikan setelah menyelesaikan program?',
            answer: 'Ya, setelah menyelesaikan program, peserta akan mendapatkan sertifikat kursus  yang menunjukkan partisipasi dalam program kelas kursushacker.id  bersama pakar hacker.'
        },
        {
            id: 7,
            question: 'Bagaimana dukungan dan aksesibilitas peserta dalam program?',
            answer: 'Kami menyediakan dukungan teknis dan aksesibilitas materi pelajaran melalui platform daring. Selain itu, Anda akan memiliki akses ke forum via telegram privat (hanya member terdaftar dan mentor)  untuk berbagi pengetahuan dan pengalaman.'
        }
    ];

    return (
        <section className="text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center my-5">
                    <h2 className="section-title fw-bold mb-5"><span className="text-info">Pertanyaan</span> yang Sering Ditanyakan</h2>
                    <p className="lh-lg w-75 mx-auto">Simak beberapa pertanyaan-pertanyaan berikut, siapa tahu salah satunya adalah pertanyaan yang ingin kamu tanyakan.</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="card card-gradient bg-transparent border-info">
                            <div className="card-body">
                                <div className="accordion accordion-flush bg-transparent" id="customAccordion">
                                    {faqs.map((faq) => {
                                        return (
                                            <div className="accordion-item bg-transparent border-0" key={faq.id}>
                                                <h2 className="accordion-header" id={`flush-heading${faq.id}`}>
                                                    <button className={`accordion-button ${faq.id !== 1 ? 'collapsed' : ''} bg-transparent shadow-none fw-bolder`} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${faq.id}`} aria-expanded={faq.id === 1 ? "true" : "false"} aria-controls={`flush-collapse${faq.id}`}>
                                                        {faq.question}
                                                    </button>
                                                </h2>
                                                <div id={`flush-collapse${faq.id}`} className={`accordion-collapse collapse ${faq.id === 1 ? 'show' : ''}`} aria-labelledby={`flush-heading${faq.id}`} data-bs-parent="#customAccordion">
                                                    <div className="accordion-body bg-dark">
                                                        <p className="lh-lg">{faq.answer}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center my-5">
                    <p className="lh-lg">Punya pertanyaan lain?</p>
                    <a className="btn btn-outline-info py-2 px-4" href="https://wa.me/6285175484822?text=Hai kursushacker. Saya ingin bergabung dengan program kursushacker bisa tolong dibantu" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className="me-2" icon={faWhatsapp} />
                        Tanya CS
                    </a>
                </div>
                <div className="row justify-content-center my-5">
                    <div className="col-12 col-md-auto text-center my-auto">
                        <span>Organized by</span>
                    </div>
                    <div className="col-auto my-auto">
                        <img height="60" src="/assets/img/logo-sibertren.png" alt="Sibertren" />
                    </div>
                    <div className="col-auto my-auto">
                        <img height="40" src="/assets/img/img-codepolitan-logo.webp" alt="Codepolitan" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionFaq;
