import CardExperience from "../CardExperience/CardExperience";

const SectionExperience = () => {
    const experiences = [
        {
            imageUrl: '/assets/img/logo-sibertren.png',
            title: 'Founder & CEO',
            description: 'April 2022 - PRESENT'
        },
        {
            imageUrl: '/assets/img/logo-sicepat.png',
            title: 'IT Security',
            description: 'May 2021 - PRESENT'
        },
        {
            imageUrl: '/assets/img/logo-carro.png',
            title: 'Cybersecurity',
            description: 'July 2021 - PRESENT'
        },
        {
            imageUrl: '/assets/img/logo-pendekarsiber.png',
            title: 'Cybersecurity',
            description: 'July 2021 - PRESENT'
        },
        {
            imageUrl: '/assets/img/logo-sinarmas.png',
            title: 'IT Security Consultant',
            description: 'Mar 2021 - Mei 2021'
        },
        {
            imageUrl: '/assets/img/logo-bca.png',
            title: 'IT Security Consultant',
            description: 'Oct 2018 - Jul 2021'
        },
        {
            imageUrl: '/assets/img/logo-julo.png',
            title: 'IT Security Consultant',
            description: 'Jun 2021 - Okt 2021'
        },
        {
            imageUrl: '/assets/img/logo-bareksa.png',
            title: 'Penetration Testing',
            description: 'Apr 2020 - Mar 2021'
        }
    ];

    return (
        <section className="text-white my-5 py-5">
            <div className="container">
                <h2 className="section-title text-center mb-5">Pengalaman <span className="text-info">Putra Aji Adhari</span></h2>
                <div className="row">
                    {experiences.map((experience, index) => {
                        return (
                            <div className="col-md-6 col-lg-3 p-3" key={index}>
                                <CardExperience
                                    thumbnail={experience.imageUrl}
                                    title={experience.title}
                                    description={experience.description}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectionExperience;
