import { faVideoCamera } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CardModule = ({ locked, title, description }) => {
    return (
        <li>
            <div className={`card card-gradient bg-transparent ${!locked ? 'border-info text-white' : 'text-muted'}`}>
                <div className="card-body">
                    <div className="d-flex gap-2">
                        <FontAwesomeIcon className={!locked ? 'text-info' : 'text-muted'} icon={faVideoCamera} />
                        <h5 className="fw-bolder">{title}</h5>
                    </div>
                    <p>{description}</p>
                    {/* <p className={!locked ? 'text-info' : 'text-muted'}>15 menit</p> */}
                </div>
            </div>
        </li>
    )
}

export default CardModule