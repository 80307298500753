import { faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionInvest = () => {
    return (
        <section id="invest" className="text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="section-title fw-bold text-center mb-5"><span className="text-info">Investasi</span> 1x Seumur Hidup</h2>
                        <p className="lh-lg">Kamu hanya perlu berinvestasi 1x seumur hidup senilai:</p>
                        <del className="fs-2" style={{ color: '#EE6BA2' }}>Rp 8.500.000</del>
                        <p className="fw-bold h1" style={{ fontSize: '3em' }}>Rp 850.000</p>

                        <div className="card card-gradient bg-transparent text-white border border-info border-2 my-5">
                            <div className="card-header bg-info" style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
                                <h2 className="text-dark fw-bold">Promo terbatas!</h2>
                            </div>
                            <div className="card-body p-lg-5">
                                <p className="fw-bolder lh-lg">Khusus kamu yang bergabung hari ini, kamu hanya perlu berinvestasi:</p>
                                <p className="fw-bold h1" style={{ fontSize: '3em', color: '#EBFF00' }}>Rp 450.000</p>
                                <a
                                    onClick={() => window.ttq.track('InitiateCheckout', {
                                        content_type: 'product',
                                        content_id: 605,
                                        value: 450000,
                                        currency: 'IDR'
                                    })}
                                    className="btn btn-info btn-lg rounded-1 px-3 py-2 my-3"
                                    href="https://pay.codepolitan.com/?slug=kursus-hacker"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon className="me-2" icon={faLockOpen} />
                                    Gabung Program
                                </a>
                                <p className="my-3">
                                    <small><span className="text-info">Catatan:</span> Harga akan naik ke depannya seiring bertambahnya materi belajar. Gabung sekarang untuk lebih hemat!</small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionInvest;
