const CardExperience = ({ title, description, thumbnail }) => {
    return (
        <div className="card card-gradient bg-transparent border-info">
            <div className="card-body">
                <img height="40px" width="auto" src={thumbnail} alt={title} />
                <h5 className="section-title mt-3">{title}</h5>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default CardExperience;
