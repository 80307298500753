import CardAwward from "../CardAwward/CardAwward";

const SectionAwwards = () => {
    const awwards = [
        // {
        //     thumbnail: '/assets/img/logo-tni.png',
        //     title: 'Increase cybersecurity awareness at TNI-AD',
        //     description: 'On this occasion, I was given the trust by the TNI-AD to be a speaker on cybersecurity, social engineering, bugbounty programs and how to prevent cyber attacks.'
        // },
        // {
        //     thumbnail: '/assets/img/logo-bssn.png',
        //     title: 'Live hacking demo at BSSN RI',
        //     description: 'BSSN (National Cyber and Crypto Agency) gave a certificate and award to Putra Aji Adhari for being a speaker and doing live hacking on the BSSN site.'
        // },
        {
            thumbnail: '/assets/img/logo-tokopedia.png',
            title: 'Certificate of appreciation from Tokopedia',
            description: 'I have participated in the bug bounty program that has been organized by Tokopedia, then I reported the security gap to Tokopedia. and Tokopedia gave an appreciation in the form of a certificate.'
        },
        {
            thumbnail: '/assets/img/logo-anteraja.png',
            title: 'Certificate of appreciation from Anteraja',
            description: 'I have received a Certificate of Appreciation from Anteraja because I have found a security flaw in the Anteraja Application and reported it to the relevant department.'
        },
        {
            thumbnail: '/assets/img/logo-nasa.png',
            title: 'Helping reduce the threat of cyberattacks at NASA',
            description: `Award from NASA (United States Aeronautics and Space Agency) because I have reported the vulnerabillity to them, (I managed to access NASA's hosting or server and get ROOT role in server)`
        },
    ];

    return (
        <section className="text-white my-5 py-5">
            <div className="container">
                <h2 className="section-title text-center mb-5"><span className="text-info">Penghargaan</span> Yang Diterima</h2>
                <div className="row justify-content-center">
                    {awwards.map((awward, index) => {
                        return (
                            <div className="col-md-6 col-lg-4 p-3" key={index}>
                                <CardAwward
                                    thumbnail={awward.thumbnail}
                                    title={awward.title}
                                // description={awward.description}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    )
}

export default SectionAwwards