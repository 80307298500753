import { faCheckCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionJoin = () => {
    const items = [
        'Akses Materi + Update Materi Seumur Hidup',
        'Forum Tanya Jawab',
        'Webinar Online Bulanan',
        'Grup Member Telegram untuk informasi dan silaturahmi',
        'Sertifikat Digital'
    ];

    return (
        <section className="text-white">
            <div className="container py-5 px-4 px-lg-5">
                <h2 className="section-title fw-bold text-center mb-5">Yuk Gabung di <span className="text-info">Kursushacker.id</span></h2>

                <div className="row justify-content-between mb-5 pb-5">
                    <div className="col-lg-6 my-auto">
                        <p className="lh-lg">Belajar sepuasnya, tidak ada batas waktu. Beli sekali akses seumur hidup. Materi update terus mengikuti perkembangan zaman dan tren industri, tanpa tambahan biaya.</p>
                        <a className="btn btn-outline-info py-2 px-3 rounded-1" href="https://youtu.be/OHunP44PYgE" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="me-2" icon={faPlayCircle} />
                            Tonton Demo Materi
                        </a>
                    </div>
                    <div className="col-lg-6 my-auto order-first order-lg-last mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-join-1.png" alt="Join 1" />
                    </div>
                </div>
                <div className="row justify-content-between mb-5 pb-5">
                    <div className="col-lg-6 my-auto">
                        <p className="lh-lg">Alur belajar terarah disusun secara komprehensif oleh para expert.Nggak usah pusing-pusing lagi mikirin roadmap belajar, tinggal ikutin aja materinya satu persatu sampai selesai.100% teruji ampuh!</p>
                        <p className="lh-lg">Pemula, belum pernah belajar coding dan nggak punya background IT juga bisa gabung. Diajarin dari 0 banget!</p>
                    </div>
                    <div className="col-lg-6 my-auto order-first mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-join-2.png" alt="Join 2" />
                    </div>
                </div>
                <div className="row justify-content-between mb-5 pb-5">
                    <div className="col-lg-6 my-auto">
                        <p className="lh-lg">Error, stuck atau punya kendala saat belajar ngikutin materi? Langsung tanyain aja di group private Telegram!</p>
                        <p className="lh-lg">Pertanyaan-pertanyaanmu akan dijawab secara sigap oleh para mentor.</p>
                        <p className="lh-lg">Forum Tanya Jawab aktif pada jam kerja.</p>
                    </div>
                    <div className="col-lg-6 my-auto order-first order-lg-last mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-join-3.png" alt="Join 3" />
                    </div>
                </div>
                <div className="row justify-content-between mb-5 pb-5">
                    <div className="col-lg-6 my-auto">
                        <p className="lh-lg">Konsultasi online via Zoom Eksklusif sebulan sekali.</p>
                        <p className="lh-lg">Nanya, curhat, konsultasi atau ngobrol langsung dengan mentor. Kamu bukan cuma nonton video doang, tapi belajar dan dibimbing langsung oleh mentor berpengalaman bukan bot.</p>
                    </div>
                    <div className="col-lg-6 my-auto order-first mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-join-4.png" alt="Join 4" />
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-lg-6 my-auto">
                        <h2 className="section-title mb-5"><span className="text-info">Fasilitas</span> Belajar</h2>
                        <p className="lh-lg">Yang akan kamu dapatkan saat kamu gabung dalam program ini</p>
                        <div className="ps-3">
                            {items?.map((item, index) => {
                                return (
                                    <div className="d-flex gap-2" key={index}>
                                        <FontAwesomeIcon className="text-info mt-1" icon={faCheckCircle} />
                                        <p>{item}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-lg-6 my-auto order-first order-lg-last mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-join-5.png" alt="Join 5" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionJoin;
