// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardModule from '../CardModule/CardModule'
// import { faLock } from '@fortawesome/free-solid-svg-icons';

const SectionModules = () => {
    const basicModules = [
        {
            title: 'Introduction Cyber Security',
            description: 'Di modul kali ini kalian akan belajar tentang apa itu cyber security dan perbedaan anatar hacker whiteHat vs blackHat'
        },
        {
            title: 'Pemahaman Penetration Testing',
            description: 'Di modul kali ini kalian akan belajar tentang Pengertian Penetration Testing serta apa itu Bug Bounty'
        },
        {
            title: 'OWASP TOP 10',
            description: 'Di modul kali ini kalian akan belajar tentang Basic OWASP Top 10 dan perbedaan anatra OWASP 2013 vs 2017'
        },
        {
            title: 'Vulnerability Management',
            description: 'Di modul kali ini kalian akan belajar tentang CVSS, CWE, CVE'
        },
        {
            title: 'AUTOMATION TOOLS',
            description: 'Di modul kali ini kalian akan belajar tentang Penetration Test Manual VS Automation, Google Dork, SQL Map, Burp Suite, DirSearch, Subdomain Finder serta Shodan.'
        }
    ];

    const intermedateModules = [
        {
            title: 'Pengenalan kepada Keamanan Sistem',
            description: 'Memahami konsep dasar dan pentingnya keamanan sistem.'
        },
        {
            title: 'Jenis-jenis Celah Keamanan',
            description: 'Studi mendalam tentang berbagai jenis celah keamanan, termasuk SQL injection, Cross-Site Scripting (XSS), dan banyak lagi.'
        },
        {
            title: 'Analisis dan Penilaian Risiko',
            description: 'Cara melakukan penilaian risiko untuk mengidentifikasi potensi celah keamanan.'
        },
        {
            title: 'Strategi Pencegahan',
            description: 'Pembelajaran tentang berbagai metode dan praktik terbaik untuk mencegah celah keamanan.'
        },
        {
            title: 'Studi Kasus',
            description: 'Analisis kasus nyata celah keamanan dan bagaimana mereka dapat dicegah.'
        }
    ];

    return (
        <section className="text-white my-5 py-5">
            <div className="container">
                <h2 className="section-title text-center mb-3">Materi <span className="text-info">Belajar</span></h2>
                <p className="text-center mb-5">Susunan roadmap belajar mulai dari nol hingga mahir</p>

                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-7">
                        <h3 className='fw-bolder'>Basic</h3>
                        <ul className="timeline mb-5">
                            {basicModules.map((module, index) => {
                                return (
                                    <CardModule
                                        key={index}
                                        title={module.title}
                                        description={module.description}
                                    />
                                );
                            })}
                        </ul>

                        <h3 className='fw-bolder'>Intermediate</h3>
                        <ul className="timeline mb-5">
                            {intermedateModules.map((module, index) => {
                                return (
                                    <CardModule
                                        key={index}
                                        title={module.title}
                                        description={module.description}
                                    />
                                );
                            })}
                        </ul>

                        {/* <h3 className='fw-bolder'>Intermediate <FontAwesomeIcon className="text-info" icon={faLock} /></h3>
                        <ul className="timeline-locked mb-5">
                            <CardModule
                                locked
                                title="Update Materi"
                                description="Untuk melengkapi materi, saat ini kami sedang dalam proses membuatan. Kamu akan mendapatkan update ini jika sudah mengikuti kelas Kursus Hacker"
                            />
                        </ul> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionModules