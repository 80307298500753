import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardBenefit = ({ iconBg, icon, title, content }) => {
    return (
        <div className="card card-gradient bg-transparent border-info h-100">
            <div className="card-body p-5">
                <span className="d-inline-block rounded-circle border-0" style={{ width: '100px', height: '100px', padding: '25px 20px', background: iconBg }}>
                    <FontAwesomeIcon size="3x" fixedWidth className="text-dark" icon={icon} />
                </span>
                <h5 className="fw-bold mt-4 mb-3">{title}</h5>
                <p className="lh-lg" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    );
};

export default CardBenefit