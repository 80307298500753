import { Helmet } from 'react-helmet-async';
import Layout from '../../components/global/Layout/Layout';
import Hero from '../../components/home/Hero/Hero';
import SectionFaq from '../../components/home/SectionFaq/SectionFaq';
import SectionInvest from '../../components/home/SectionInvest/SectionInvest';
import SectionIntro from '../../components/home/SectionIntro/SectionIntro';
import SectionExperience from '../../components/home/SectionExperience/SectionExperience';
import SectionAwwards from '../../components/home/SectionAwwards/SectionAwwards';
import SectionJoin from '../../components/home/SectionJoin/SectionJoin';
import SectionBenefits from '../../components/home/SectionBenefits/SectionBenefits';
import SectionComparing from '../../components/home/SectionComparing/SectionComparing';
import SectionModules from '../../components/home/SectionModules/SectionModules';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Kursus Hacker - Belajar Jadi Hacker dari Nol</title>
            </Helmet>
            <Layout>
                <Hero />
                <SectionIntro />
                <SectionExperience />
                <SectionAwwards />
                <SectionJoin />
                <SectionModules />
                <SectionBenefits />
                <SectionComparing />
                <SectionInvest />
                <SectionFaq />
            </Layout>
        </>
    );
};

export default Home;
