import { faBriefcase, faHatCowboy, faHouseLaptop, faSackDollar } from "@fortawesome/free-solid-svg-icons";
import CardBenefit from "../CardBenefit/CardBenefit";

const SectionBenefits = () => {
    const benefits = [
        {
            icon: faBriefcase,
            iconBg: '#BFD342',
            title: 'Permintaan Kerja yang Tinggi',
            content: 'Industri cyber security terus berkembang seiring meningkatnya serangan siber. Perusahaan di seluruh dunia sedang berlomba-lomba mencari tenaga ahli keamanan siber. Menjadi ahli di bidang ini dapat meningkatkan peluang Anda mendapatkan pekerjaan dengan gaji yang kompetitif.'
        },
        {
            icon: faHouseLaptop,
            iconBg: '#01D5D8',
            title: 'Keterampilan yang Bermanfaat Untuk Kehidupan Sehari-hari',
            content: 'Mengetahui cara melindungi diri Anda dan keluarga Anda di dunia digital menjadi semakin penting. Dengan kemampuan cyber security, Anda dapat menjaga data pribadi dan transaksi online Anda tetap aman.'
        },
        {
            icon: faSackDollar,
            iconBg: '#42D351',
            title: 'Penghasilan Tambahan',
            content: 'Menjadi seorang bug hunter atau konsultan keamanan siber memberikan Anda kesempatan untuk mendapatkan penghasilan tambahan, selain dari pekerjaan utama Anda.'
        },
        {
            icon: faHatCowboy,
            iconBg: '#CC6BEE',
            title: 'Kesempatan Untuk Memberi Dampak Nyata',
            content: 'Dengan menguasai cyber security, Anda memiliki kesempatan untuk melindungi data dan informasi penting, serta mencegah serangan yang dapat merusak reputasi dan keuangan perusahaan.'
        }
    ];

    return (
        <section className="text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center my-5">
                    <h2 className="section-title fw-bold">Apa Untungnya Jadi <span className="text-info">Cybersecurity?</span></h2>
                </div>

                <div className="row">
                    {benefits.map((benefit, index) => {
                        return (
                            <div className="col-md-6 p-3 mb-4" key={index}>
                                <CardBenefit
                                    iconBg={benefit.iconBg}
                                    icon={benefit.icon}
                                    title={benefit.title}
                                    content={benefit.content}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectionBenefits;
